export const MenuItems=[
    {
        title:"Home",
        url:"/",
        cName :"nav-links",
        icon:"fa-solid fa-house-user"
    },
    {
        title:"Skin",
        url:"/Skin",
        cName :"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    {
        title:"Hair",
        url:"/Hair",
        cName :"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    {
        title:"Laser",
        url:"/Laser",
        cName :"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    {
        title:"Dental",
        url:"/Dental",
        cName :"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    {
        title:"Semi-permanent Makeup",
        url:"/SemipermanentMakeup",
        cName :"nav-links",
        icon:"fa-solid fa-circle-info"
    },
    

    
    // {
    //     title:"Location",
    //     url:"/Location",
    //     cName :"nav-links",
    //     icon:"fa-solid fa-briefcase"
    // }, 
   

]